/* You can add global styles to this file, and also import other style files */
@use './mixins.scss' as Mixins;
@use './variables.scss' as Variables;

@use './app/styles/toast.style.scss';
@use './app/styles/card.style.scss';
@use './app/styles/modal.style.scss';

* {
	margin: 0;
	padding: 0;
	font-family: 'Lato', sans-serif;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

.hidden {
	display: none;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

body {
	margin: auto;
	box-sizing: border-box;
	height: 100%;
}

:root {
	--p-carousel-indicator-width: 1rem !important;
	--p-carousel-indicator-height: 1rem !important;
	--p-carousel-indicator-active-background: #3e7efd !important;
	--p-carousel-indicator-background: #000 !important;
	// --p-carousel-indicator-hover-background: #fff !important; // how to disabled hover?
}

:root {
	--p-button-secondary-background: white !important;
	--p-button-secondary-hover-background: #c9c9c9 !important;
	--p-button-secondary-color: #000000 !important;
	--p-button-secondary-hover-border-color: #000000 !important;
	--p-button-secondary-border-color: #000000 !important;
	--p-button-secondary-hover-color: #000000 !important;

	--p-button-outlined-secondary-border-color: black !important;
	--p-button-outlined-secondary-color: black !important;
	--p-button-outlined-secondary-background: #c9c9c9 !important;

	--p-button-outlined-secondary-hover-background: #e1e1e1 !important;
	--p-button-outlined-secondary-hover-border-color: white !important;
	--p-button-outlined-secondary-hover-color: white !important;

	--p-dialog-content-padding:0px !important;
	--p-dialog-header-padding:0px !important;
}

.app-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

:root {
	--p-toast-success-background: rgb(255, 255, 255) !important;
	--p-toast-success-border-color: rgb(202, 246, 181) !important;
	--p-toast-success-color: black !important;
	--p-toast-success-shadow: black !important;
	--p-toast-success-detail-color: black !important;

	--p-toast-info-background: rgb(255, 255, 255) !important;
	--p-toast-info-border-color: rgb(147, 181, 255) !important;
	--p-toast-info-color: black !important;
	--p-toast-info-shadow: black !important;
	--p-toast-info-detail-color: black !important;

	--p-toast-error-background: rgb(255, 255, 255) !important;
	--p-toast-error-border-color: rgb(255, 147, 147) !important;
	--p-toast-error-color: black !important;
	--p-toast-error-shadow: black !important;
	--p-toast-error-detail-color: black !important;

	--p-toast-width: 20rem !important;

	--p-toast-border-radius: 8px !important;

	--p-tag-danger-background: rgb(255, 147, 147) !important;

	--p-tag-font-weight: 0 !important;
}
.p-menu-item-link:hover{
	cursor: pointer;
	color: Variables.$btn-color-1 !important;
	text-decoration: none;
	&:hover {
		text-decoration: none !important;
	}
}
p-message {
	& div {
		border-radius: 8px !important;
		background: transparent !important;
	}
}
.p-fileupload{
	border-radius: 8px !important;
}

.p-toast-message-success {
	& .p-toast-close-button {
		color: black !important;
	}
}

.p-toast-message-info {
	& .p-toast-close-button {
		color: black !important;
	}
}

.p-toast-message-info, .p-toast-message-success, .p-toast-message-warn, .p-toast-message-error, .p-toast-message-secondary, .p-toast-message-contrast {
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.11) !important;
}

 :root {
    --p-button-primary-hover-background: #4581f5 !important;
	--p-button-transition-duration:250ms !important;
 }

 .carousel-item{
	width:100% !important;
 }

a {
	cursor: pointer;
	color: Variables.$accent-blue !important;
	text-decoration: none;
	&:hover {
		text-decoration: underline !important;
	}
}

ul {
	list-style-type: none;
}

app-root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: Variables.$global-background !important;
}

app-header {
	& header {
		background: white !important;
		margin-bottom: 2.5rem !important;
		@media screen and (max-width: Variables.$max-width2) {
			margin-bottom: 1.25rem !important;
		}
	}
}

.p-button:not(.p-button-rounded) {
	width: 100%;
	border-radius: 8px !important;
	height: 100%;
}

p-dialog .p-button-text.p-button-secondary:not(:disabled):hover {
	background-color: white !important;
}

p-button:not([severity="secondary"]):not([severity="danger"]):not([severity="success"]):not([severity="warn"]) {
	.p-button:not(.p-button-icon-only) {
		&:not(.p-toast-close-button):hover {
			background: #396cd6 !important;
			color: #ffff !important;
			transition: 250ms all ease !important;
			border: .2px solid #396cd6 !important;
		}

		&[disabled]:hover {
			background: #3e7efd !important;
		}

		&.p-toast-close-button {
			background: transparent;
			color: #000000 !important;
			transition: 250ms all ease !important;
			border: transparent !important;
		}

		&.p-toast-close-button:hover {
			background: transparent;
			color: #000000 !important;
			transition: 250ms all ease !important;
			border: transparent !important;
		}

		&.without-background {
			background: transparent !important;
			color: #3e7efd !important;
			&:hover {
				background: #3e7efd !important;
				color: #ffff !important;
			}
		}
	}
}

p-button[severity="success"] {
	& .p-button {
		&.p-button-outlined {
			background: transparent !important;
			color: #22c55e !important;
			&:hover {
				background: #22c55e !important;
				color: #ffff !important;
			}
		}
	}
}

p-button[severity="warn"] {
	& .p-button {
		border-color: #fb923c !important;
		&.p-button-outlined {
			background: transparent !important;
			color: #fb923c !important;
			&:hover {
				background: #fb923c !important;
				color: #ffff !important;
			}
		}
		&:hover {
			background-color: #fb923c !important;
			color: #ffff !important;
		}
	}
}

p-button[severity="danger"] {
	& .p-button {
		&.p-button-outlined {
			background: transparent !important;
			color: var(--p-button-outlined-danger-color) !important;
			&:hover {
				background: #fecaca !important;
			}
		}
	}
}

.app {
	&__spinner {
		@include Mixins.flex-direction-column-center;
		width: 100vw;
		height: 100vh;
	}

	&__toast {
		top: 2.5rem !important;
		right: 1.25rem !important;
		padding-left: 1.25rem !important;
	}
}

.body-background {
	@include Mixins.container;
	flex-direction: column;
	margin-bottom: 2.5rem;
	@media screen and (min-width: Variables.$max-width1 + 1px){
		padding: 2.5rem;
	}

	@media screen and (min-width: Variables.$max-width2 + 1px) and (max-width: Variables.$max-width1) {
		padding: 1.5rem 1.5rem 1rem;
	}

	@media screen and (min-width: Variables.$max-width3 + 1px) and (max-width: Variables.$max-width2) {
		padding: 1rem 1rem 1rem;
	}

	@media screen and (max-width: Variables.$max-width3) {
		padding: 1rem 1rem 1rem;
	}

	border-radius: 8px;
	background-color: Variables.$accent-background;
}

.p-card-body {
	padding: 0 !important;
	& .p-card-content img {
		height: 13.5rem !important;
		width: 18rem !important;
		object-fit: cover;
		@media screen and (max-width: Variables.$max-width1 + 1px) {
			height: 9rem !important;
			width: 100% !important;
			object-fit: cover;
		}
		@media screen and (max-width: Variables.$max-width2 + 1px) {
			height: 9rem !important;
			width: 100% !important;
		}
	}
}

.p-card {
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.11) !important;
	border-radius: 8px !important;
}

.p-carousel {
	&-indicator-button {
		border-radius: 50% !important;
	}
}

// -------------------------------- search-filters ---------------------------------------------------------------------
.search-filters {
	& p-iconfield {
		@include Mixins.search-filters-elem;
		margin-bottom: 1.25rem !important;

		& .p-iconfield {
			@include Mixins.search-filters-elem;
			& input {
				@include Mixins.search-filters-elem;
				border-radius: 8px !important;
			}
			& .p-inputicon {
				top: 1.4rem !important;
			}
		}

		& small {
			line-height: 1.75;
			font-size: 1rem;
			padding-left: 0.6rem;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	& p-datePicker {
		@include Mixins.search-filters-elem;
		margin-bottom: 1rem !important;

		& .p-datepicker {
			@include Mixins.search-filters-elem;

			& .p-inputtext {
				border-radius: 8px !important;
			}
		}
	}

	&--btn {
		@include Mixins.search-filters-elem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@media screen and (max-width: Variables.$max-width2) {
			flex-direction: column;
			height: auto;
		}

		& p-button[type="submit"] {
			width: 12.8125rem !important;
			height: 3rem;
			@media screen and (max-width: Variables.$max-width1) {
				width: 9rem !important;
			}
			@media screen and (max-width: Variables.$max-width2) {
				width: 100% !important;
				height: 3rem;
			}
		}

		&__settings-icon {
			height: 3rem;
			width: 3.4375rem !important;
			@media screen and (max-width: Variables.$max-width2) {
				display: none;
			}
		}

		&__settings-btn {
			height: 3rem;
			width: 100% !important;
			margin-bottom: 0.5rem;
			@media screen and (min-width: Variables.$max-width2 + 1px) {
				display: none;
			}
		}
	}
}


// ----------------------- header -------------------------------------
.nav__right-side p-button:not(.nav__profile-icon) {
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	padding-right: 0.5rem;
}

.nav .nav__premium-btn button {
	@media screen and (max-width: Variables.$max-width2) {
		font-size: 0.75rem !important;
	}
}

.nav__profile-icon {
	width: 3rem;
	height: 3rem;
	& button {
		width: 100%;
		height: 100% !important;
	}
}

.p-dialog-header {
	padding-left: 1rem !important;
	padding-top: 0.5rem !important;
}

.p-dialog{
	max-height: 100% !important;
	@media screen and (max-width: Variables.$max-width2) {
		padding-bottom: 5.1875rem !important;
	}
}

.p-autocomplete-clear-icon{
	height: 1.3rem !important;
	width: 1.3rem !important;
	top: 45% !important;
}

.p-dialog-content {
	overflow-y: hidden;
	padding: 1rem !important;
}

.sign {
	input {
		height: 2.2rem !important;
	}
}

p-password {
	width: 100%;
	margin-top: 1rem;
	height: 2.2rem;
	& div {
		width: 100%;
		height: 2.2rem;
		& input {
			width: 100%;
			border-radius: 8px !important;
			padding-top: 0.3rem;
		}
	}
}

spinnericon svg {
	animation: spin 1s linear infinite !important;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}


// -------------------- paginator -------------------------------

.p-paginator {
	padding-bottom: 2.5rem !important;
	padding-top: 0 !important;
	border-bottom-left-radius: 8px !important;
	border-bottom-right-radius: 8px !important;

	@media screen and (max-width: Variables.$max-width3) {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	& button {
		border: #1e1d1d solid 1px;
		&.p-disabled {
			border: var(--p-paginator-nav-button-color) solid 1px;
		}
		&.p-paginator-page-selected {
			border: Variables.$accent-blue !important;
			background-color: Variables.$accent-blue !important;
		}
	}

	& .p-paginator-first,
	& .p-paginator-last,
	& .p-paginator-prev,
	& .p-paginator-next {
		border-radius: 0.25rem;
	}
	margin-bottom: 4rem;

}


// ----------------- profile

.profile-icon_edit {
	width: 2rem;
	height: 2rem;
	@media screen and (max-width: Variables.$max-width2) {
		width: 1rem;
		height: 1rem;
	}

	& button {
		width: 100%;
		height: 100% !important;
	}
}

.profile-card__right {
	p-button {
		margin-bottom: 0.75rem;
		& button {
			width: 2rem !important;
			height: 2rem !important;

			@media screen and (max-width: Variables.$max-width2) {
				width: 1.6rem !important;
				height: 1.6rem !important;
			}
		}
		& .pi {
			font-size: 0.8rem;
			@media screen and (max-width: Variables.$max-width2) {
				font-size: 0.7rem;
			}
		}
	}
}

@media screen and (min-width: Variables.$max-width2 + 1px) {
	.profile-btn_edit {
		display: none;
	}
}

@media screen and (max-width: Variables.$max-width2) {
	.profile-icon_edit {
		display: none;
	}
}

p-tabs {
	@include Mixins.container;
	padding: 0 !important;
	margin-top: 2.7rem !important;
	@media screen and (max-width: Variables.$max-width2) {
		margin-top: 1.4rem !important;
	}
	& p-tablist {
		& div.p-tablist-content {
			& div.p-tablist-tab-list {
				display: flex !important;
				border-top-left-radius: 8px !important;
				border-top-right-radius: 8px !important;
				padding-left: 2.5rem !important;
				padding-top: 1rem !important;
				border-color: #d3d3d3;
				@media screen and (max-width: Variables.$max-width1) {
					padding-left: 1.25rem !important;
				}
				@media screen and (max-width: Variables.$max-width3) {
					width: 100% !important;
					& i {
						display: none;
					}
				}
				& p-tab {
					padding: 1rem 2rem 1.5rem 0;
					font-size: 1.5rem !important;
					font-weight: normal !important;
					font-family: 'Lato', sans-serif !important;
					color: #000000;
					border-top-left-radius: 8px !important;
					border-top-right-radius: 8px !important;

					@media screen and (max-width: Variables.$max-width2) {
						font-size: 1.25rem !important;
						margin: 1rem !important;
						padding: 0 !important;
					}

					@media screen and (max-width: Variables.$max-width3) {
						margin-left: 0.25rem !important;
						margin-right: 1rem !important;
						margin-top: 0 !important;
						border-bottom: #1a1a1a 1px solid !important;
						background-color: #ffffff !important;
					}
					& div {
						i {
							margin-right: 0.7rem !important;
						}
						margin: auto;
					}
					&[data-p-active="true"] {
						border-bottom: #1a1a1a 1px solid !important;
						background-color: #ffffff !important;
					}
					&:hover {
						border-bottom: #1a1a1a 1px solid !important;
						background-color: #ffffff !important;
					}
				}
			}
		}
	}
}


// ----------------- home modal map

.map__container {
	@include Mixins.container;
	flex-direction: column;
	padding: 2.5rem !important;
	background-color: white;
	border-radius: 8px !important;
	margin-bottom: 2.5rem !important;
}

.card {
	&__container {
		@include Mixins.container;
		padding: 0;
		display: block;
		min-height: 100%;
		background-color: #ffffff;
		margin-bottom: 2.5rem;
		border-radius: 8px;
	}

	&-list {
		@include Mixins.wrap-between;
		display: flex;
		padding: 2.5rem;

		@media screen and (max-width: Variables.$max-width1) {
			padding: 1.25rem;
		}
		@media screen and (max-width: Variables.$max-width3) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}

		& app-events-item {
			margin-bottom: 1.5rem;
			width: 17.5rem;

			@media screen and (max-width: Variables.$max-width1) {
				width: 13rem;
				font-size: 0.75rem;
			}
			@media screen and (max-width: Variables.$max-width2) {
				width: 12.5rem;
			}
			@media screen and (max-width: Variables.$max-width3) {
				width: 100%;
				margin-right: 0.75rem;
				margin-left: 0.75rem;
			}
		}
	}

	&-title {
		padding-left: 2.5rem;
		padding-top: 2.5rem;
		font-size: 1.7rem;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;

		@media screen and (max-width: Variables.$max-width1) {
			padding-left: 1.25rem;
			padding-top: 1.25rem;
			font-size: 1.5rem;
		}

		font-weight: normal;
		background-color: white;
	}

	&-title {
		padding-left: 2.5rem;
		padding-top: 2.5rem;
		font-size: 1.7rem;

		@media screen and (max-width: Variables.$max-width1) {
			padding-left: 1.25rem;
			padding-top: 1.25rem;
			font-size: 1.5rem;
		}
	}
}

// --------------------------- event-card

.edit-card-configure-block {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: Variables.$max-width2) {
		flex-direction: column;
	}
	margin-bottom: 2rem;

	& p-datePicker {
		@media screen and (max-width: Variables.$max-width2) {
			margin-bottom: 1rem !important;
		}
		& span.p-datepicker {
			@include Mixins.event-card-elem;
			& input {
				@include Mixins.event-card-elem;
			}
		}
	}

	& div.p-autocomplete-container {
		@include Mixins.event-card-elem;
		@media screen and (max-width: Variables.$max-width2) {
			margin-bottom: 2rem !important;
		}

		& div input {
			@include Mixins.event-card-elem; // TODO понять что за херня
		}
	}

	& p-select{
		@include Mixins.event-card-elem;
		@media screen and (max-width: Variables.$max-width2) {
			margin-bottom: 1rem !important;
		}
		padding-top: 0.2rem !important;
	}

	& p-multiselect{
		@include Mixins.event-card-elem;
		@media screen and (max-width: Variables.$max-width2) {
			margin-bottom: 1rem !important;
		}
		padding-top: 0.2rem !important;
	}
}

.p-carousel-item-list {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	& .p-carousel-item {
		padding-left: 0.75rem !important;
		@media screen and (max-width: Variables.$max-width1) {
			padding-left: 0.5rem !important;
		}
		@media screen and (max-width: Variables.$max-width2) {
			padding-left: 0 !important;
		}
	}
}

.footer-contacts {
	& a {
		color: black !important;
	}
}



// --------- file upload ---------------

.p-fileupload-file-actions {
	display: none;
}

.p-fileupload-content {
	padding: 0 !important;
}

.p-fileupload-file-list {
	margin-top: 0 !important;
}

.p-fileupload-file {
	padding: 0 !important;
	flex-direction: column;
}

.p-fileupload-file-info {
	display: none !important;
}

.p-fileupload {
	padding: 1.25rem !important;
}

.p-fileupload-file-thumbnail {
	width: 38rem !important;
	@media screen and (max-width: Variables.$max-width1) {
		width: 24rem !important;
	}
	@media screen and (max-width: Variables.$max-width3) {
		width: 15rem !important;
	}
	margin: auto;
}

.p-fileupload-header {
	padding: 0 !important;
	& p-button {
		& button {
			height: 2.5rem !important;
		}

	}
}

:root {
	--p-fileupload-progressbar-height: 0 !important;
}

input:not(.invalid-input) {
	padding-left: 0.75rem !important;
	border: 1px Variables.$input-border-color solid;
}

input:focus,
textarea:focus {
	border: 2px solid #3e7efd !important;
	outline: none !important;
}

p-tag {
	& span {
		border-radius: 8px !important;
	}
}

.p-fileupload-file{
	height: 250px;
	& img{
		max-height: 100%;
	}
}

.p-fileupload-header{
	@media screen and (max-width: Variables.$max-width3) {
		display: flex;
		flex-direction: column;
		p-button{
			width: 100%;
		}
	}
}
.p-fileupload-file{
	height: 250px;
	& img{
		max-height: 100%;
	}
}

.p-fileupload-header{
	@media screen and (max-width: Variables.$max-width3) {
		display: flex;
		flex-direction: column;
		p-button{
			width: 100%;
		}
	}
}
